import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { t } from "i18next";
import { useNavigate } from 'react-router-dom';
import { Loading } from '../Common/Loading.js';
import callServerApi from '../Utils/apiManager';
import ResponseAlert from '../Common/ResponseAlert.js';
import AutocompletePhoneNumber from '../Utils/autocompletePhoneNumber.js';
import CustomNavDropdown from '../Utils/customNavDropdown.js';
import CustomCarousel from '../Utils/customCarousel.js';

import helper from "../../helper/fn.js";
import { Button, Nav, ProgressBar, Form, InputGroup} from 'react-bootstrap';



class Recharge extends Component {
    constructor(props){
        super(props);
        this.state = {
            step:1,
            loading:false,
            btnTitle:'Recharge',
            title:'Ready to send a top-up?',
            responseError: '', responseSuccess: '',
            prefixesData:null, prefixSelected: null ,
            phoneNumber:'', confirmNumber:false,
            providersData:null, providerSelected:null,providerDropdownSelected:null,
            productsData:null, productsSelected:null, productSelected:null,productDropdownSelected:null,
            bonusData:null,beneficiaryData:null, showBeneficiaryName:null,
            rechargeTypeData:null, rechargeTypeSelected:null,rechargeTypeDropdownSelected:null, //instant or not instant
            orderData:null,orderMode:null,
            pay: null, orderId:null,
            responseCheckout: null, //for init payment
            paymentProgress: 70
        }
        this.payformRef = React.createRef();
        this.beneficiaryName = React.createRef();
    }
    componentDidUpdate(prevProps, prevState) {
        const { prefixSelected, phoneNumber, providerSelected } = this.state;
        // Actualización de prefixSelected
        if (prefixSelected !== prevState.prefixSelected) {
            if (prefixSelected) {
                helper.setPhoneNumberToStorage(prefixSelected, phoneNumber)
            } else { // prefixSelected === null
                helper.clearClientStorage();
            }
        }
        // Actualización de phoneNumber si prefixSelected está definido
        if (prefixSelected && phoneNumber !== prevState.phoneNumber) {
            if (phoneNumber) {
                helper.setPhoneNumberToStorage(prefixSelected, phoneNumber)
            } else {
                localStorage.removeItem('phoneNumber');
                localStorage.removeItem('providerObj');
            }
        }
        // Actualización de providerSelected
        if (providerSelected && providerSelected !== prevState.providerSelected) {
            localStorage.setItem('providerObj', JSON.stringify(providerSelected));
            if(providerSelected.provider_code)  this.getProduct(providerSelected.provider_code);
        }

    }

    /*
        oData = {prefixSelected, phoneNumber, providers}
        @AutocompletePhoneNumber
    */
    handlePhoneNumberResult = (oData) => {
        if (oData.prefixSelected && oData.phoneNumber && oData.providers){
            this.setState({loading:false, prefixSelected:oData.prefixSelected , phoneNumber:oData.phoneNumber});
            this.setState({providersData: oData.providers, step:2, title:'recharges.Shipping information'});
        }else if (oData.prefixSelected && oData.phoneNumber && oData.providerSelected){
            this.setState({
                loading:false, prefixSelected:oData.prefixSelected , phoneNumber:oData.phoneNumber,
                providerSelected: oData.providerSelected, title:'recharges.Shipping information',
                step:3
            });
        }
    }
    /* ------------------------
    * PROVIDERS
    *------------------------*/
    reloadProvider = () => {
        const {providersData} = this.state;
        this.setState({providerSelected:null, step:2, loading:false});
        localStorage.removeItem('prefixObj');
        if (!providersData) {
            this.getProviders();
        }
    }
    getProviders = () => {
        const {prefixSelected, phoneNumber} = this.state;
        if (!prefixSelected) { this.setState({step:1, phoneNumber:null,prefixSelected:null,confirmNumber:false}); return false; }
        if (!phoneNumber) {  this.setState({step:1, phoneNumber:null,confirmNumber:false}); return false; }
        this.setState({loading:true});
        callServerApi('get-providers?countryIsos='+prefixSelected.country_iso, 'GET')
          .then(result => {
            if (result.status === 'ok' && result.data && result.data.length > 0){
                const providerObjString = localStorage.getItem('providerObj');
                if (providerObjString) {
                    const providerObj = JSON.parse(providerObjString);
                    const providerCode = providerObj.provider_code;

                    result.data.sort((a, b) => {
                        return a.provider_code === providerCode ? -1 : b.provider_code === providerCode ? 1 : 0;
                    });
                }
              this.setState({providersData:result.data, loading:false});
            }else{
                this.handleError(result.message);
            }
          })
          .catch(error => {
            this.handleError(error.message);
          });
    }
    handleSelectProvider = (selected) => {
        const filtered = this.state.providersData.filter(option =>
            option.id === selected.id
        );
        if (filtered && filtered.length > 0){
            this.setState({providerDropdownSelected:filtered[0]});
        }
    }
    setProviderSelected = () => {
        const { providersData, providerDropdownSelected } = this.state;
        let selected = null;
        if (providerDropdownSelected){ selected =  providerDropdownSelected;
        }else if (providersData && providersData.length > 0) selected = providersData[0];
        this.setState({providerSelected: selected,  step:3});
        //this.getProduct(selected.provider_code);
    }
    /* ------------------------
    * 3 RECHARGE IMPORT *AUTH
    *------------------------*/
    getProduct= (provider_code) => {
        const token = localStorage.getItem('token');
        if (!token) {
            localStorage.setItem('from', 'recharge');
            this.props.navigate('/signin');  // Redirigir a la página de inicio de sesión
        } else {
            this.setState({loading:true});
            callServerApi('get-product', 'GET', {providerCode:provider_code}, true)
            .then(result => {
                if (result.status === 'ok' && result.data){
                    this.setState({loading:false,step:3,
                        productsData: (result.data.topUp && result.data.topUp.length) ? result.data.topUp : null,
                        bonusData: (result.data.bonus && result.data.bonus.length) ? result.data.bonus : null,
                        productsSelected:null
                    });
                } else {
                    this.handleError(result.error);
                }
            })
            .catch(error => {
                this.handleError(error.message);
            });
        }
    }
    handleSelectProduct = (selected) => {
        const filtered = this.state.productsData.filter(option =>option.id === selected.id);
        if (filtered && filtered.length > 0){
            this.setState({productDropdownSelected:filtered[0]});
        }

    }
    setProductSelected = ()=> {
        const { productsData, productDropdownSelected } = this.state;
        let productsSelected = null;
        if (productDropdownSelected){ productsSelected =  productDropdownSelected;
        }else if (productsData && productsData.length > 0) productsSelected = productsData[0];
        //instant or not instat rechargeSelected:null,rechargeTypeData:null,

        const rechargeTypeData = [];
        let o = null;
        if (productsSelected.send_value && productsSelected.send_value.instant){
            o =  productsSelected.send_value.instant;
            o.id = 'instant'; o.name=t('recharges.instantpay'); o.amount = String(o.value) + ' ' + o.currency;
            rechargeTypeData.push(o);
        }
        if (productsSelected.send_value && productsSelected.send_value.not_instant){
            o =  productsSelected.send_value.not_instant;
            o.id = 'not_instant'; o.name=t('recharges.not_instantpay'); o.amount = String(o.value) + ' ' + o.currency;
            rechargeTypeData.push(o);
        }
        this.setState({productsSelected: productsSelected, rechargeTypeData:rechargeTypeData });
    }
    handleSelectBonus = (selected) => {debugger
        const filtered = this.state.bonusData.filter(option =>option.id === selected.id);
        if (filtered && filtered.length > 0){
            const bonus = filtered[0];
            const rechargeTypeData = [];
            let o = null;
            if (bonus.send_value && bonus.send_value.instant){
                o =  bonus.send_value.instant;
                o.id = 'instant'; o.name=t('recharges.instantpay'); o.amount = String(o.value) + ' ' + o.currency;
                rechargeTypeData.push(o);
            }
            if (bonus.send_value && bonus.send_value.not_instant){
                o =  bonus.send_value.not_instant;
                o.id = 'not_instant'; o.name=t('recharges.not_instantpay'); o.amount = String(o.value) + ' ' + o.currency;
                rechargeTypeData.push(o);
            }
            this.setState({productsSelected: bonus, rechargeTypeData:rechargeTypeData });
        }
    }


    handleSelectRechargeType = (selected) => {
        const filtered = this.state.rechargeTypeData.filter(option =>
            option.id === selected.id
        );
        if (filtered && filtered.length > 0){
            this.setState({rechargeTypeDropdownSelected:filtered[0]});
        }
    }
    chooseRechargeType=()=> {
        const { productsSelected, rechargeTypeData, rechargeTypeDropdownSelected } = this.state;
        let selected = null;
        if (rechargeTypeDropdownSelected){ selected =  rechargeTypeDropdownSelected;
        }else if (rechargeTypeData && rechargeTypeData.length > 0) selected = rechargeTypeData[0];
        this.setOrderData(productsSelected, selected, selected.id);
    }

    setOrderData = (product, send_value, mode) => {
        const {prefixSelected, phoneNumber, providerSelected} = this.state;
        const orderData = {
                "product_id": product.id,
                "concept_id": 1,//Default,
                "phone_number" : helper.patterPhoneNumberToSend(prefixSelected.value, phoneNumber), //prefixSelected.rlabel + phoneNumber,
                "provider_code": providerSelected.provider_code,
                "sku_code": product.sku_code,
                "localization_key": product.localization_key,
                "receive_value": product.receive_value,
                "receive_currency": product.receive_currency,
                "send_value": send_value.value,
                "send_currency": send_value.currency,
                "processing_mode": mode,
                "redemption_mechanism": "Immediate",
                "quantity": 1,
                "price": send_value.value
        };
        const beneficiaryData = localStorage.getItem('beneficiaryObj');
        this.setState({
            title: 'recharges.Shipping information',
            orderData:orderData, orderMode:mode,
            beneficiaryData: (beneficiaryData) ? beneficiaryData : null,
            step:4
        });
    }

    sendOrder = () => {
        const {orderData} = this.state;
        const ko = () =>   this.setState({loading:false, step:4, orderId:null});
        if (!orderData) {  ko(); return; }
        this.setState({step:5, pay:true});
        if (this.beneficiaryName.current && this.beneficiaryName.current.value){
            this.setState({beneficiaryData:this.beneficiaryName.current.value});
            this.saveNewBeneficiary();
        }
        //remove localstorage
        const data = {
            total:orderData.price,
            details: [orderData]
        };

        //console.log('step',this.state.step);
        callServerApi('new-order', 'POST', data, true)
          .then(result => {
            if (result.status === 'ok' && result.data && result.data.order_id){
                this.setState({pay:true, orderId:result.data.order_id, paymentProgress: 80});
                ///checkout, el react envia order_id y locale, backend retorna data.url_checkout
                this.initCheckOut(result.data.order_id);
            }else{
                this.handleError(result.message);
                ko();
            }
          })
          .catch(error => {
            this.handleError(error.message);
            ko();
          });
    }
    initCheckOut = (orderId) => {
        const ko = () =>   this.setState({loading:false, step:4, orderId:null});
        if (!orderId) { ko(); return; }

        callServerApi('checkout', 'POST', {order_id:orderId}, true)
          .then(result => {
            this.setState({loading:false});
            if (result.status === 'ok' && result.data){
                if (result.data.url_checkout) window.location.href = result.data.url_checkout; //redirect to url
                else {
                    this.setState({responseCheckout: result.data, paymentProgress: 85 });
                    setTimeout(() =>  { this.setState({paymentProgress: 90 }) }, 500);
                    setTimeout(this.triggerPaymentSubmit, 1000);
                }
                helper.clearClientStorage();
            }else{
                this.handleError(result.message);
                ko();
            }
          })
          .catch(error => {
            this.handleError(error.message);
          });
    }
    triggerPaymentSubmit = () =>{ console.log('triggerPaymentSubmit', this.payformRef);
        this.setState({paymentProgress: 95 });
        if (this.payformRef.current) {
            this.payformRef.current.submit();  // Dispara el submit del formulario
        }
    }
    resetOrder = () =>{
        this.setState({productsSelected:null, step:3, loading:false});
    }
    saveNewBeneficiary = () =>{
        const {prefixSelected, phoneNumber,providerSelected} = this.state;
        const d = {
            name: this.beneficiaryName.current.value,
            countryIsos: (this.state.prefixSelected) ? this.state.prefixSelected.country_iso : '',
            phone: helper.patterPhoneNumberToSend(prefixSelected.value, phoneNumber),
            providerCode: providerSelected.provider_code,
        }
        callServerApi('client/new-beneficiaries', 'POST', d, true)
        .then(result => {
            console.debug(result);
        }).catch(error => {
            console.debug(error);
        });
    }

    handleError(message) {
        this.setState({ responseError: message || '', loading: false });
        setTimeout(() => { this.setState({ responseError: '' }); }, 5000);
    }

    handleCheckboxChange = (event) => {
        this.setState({ showBeneficiaryName: event.target.checked });
        if (this.beneficiaryName && this.beneficiaryName.current){  this.beneficiaryName.current.focus(); }
    };
    render(){
            const { loading,  title, btnTitle, step,  responseError,beneficiaryData,
                prefixSelected, phoneNumber, providersData, providerSelected,
                productsData, productsSelected, bonusData,
                rechargeTypeData, orderData, orderMode, pay, responseCheckout, }  = this.state;
        return (
        <Translation ns={['translation']}>
        { (t) =><>
            {loading && <Loading/> }

            {!loading &&
            <div className="box-wrap">
                {step > 1 && <div className='refresh-link'>
                    <Nav activeKey="/recharge">
                        <Nav.Item>
                            <Nav.Link href="/recharge" onClick={helper.clearClientStorage}><i className="fa fa-angle-left" aria-hidden="true"></i> {t("recharges.Start a new recharge")} </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>}
                {responseError && <ResponseAlert response={responseError} variant="danger" /> }

                {step === 1 && <div>

                    {(!prefixSelected || !phoneNumber) && <>
                        <div className="info text-center mb-4"> <h4 className="title-box">{t(title)}</h4></div>
                        <AutocompletePhoneNumber placeholder={t('recharges.where are you sending to')} btnTitle={btnTitle} handleResult={this.handlePhoneNumberResult}/>
                    </> }

                </div> }
                {step > 1 && title && <div className='text-center border-bottom'>
                    <h4 className="title-box">{t(title)}</h4>
                </div>}

                {step > 1 && prefixSelected  &&  <div className='row justify-content-between mt-4 flex-wrap'>
                        <div className='col-6 col-md-2 text-left order-1 '>{t('recharges.country')}: </div>
                        <div className='col-12 col-md-8 text-center order-3 order-lg-2'>
                            <img className="thumbnail-image mini mr-4 rounded-circle "
                                src={prefixSelected.img}
                                alt={prefixSelected.label}
                            />
                            <span><b>{prefixSelected.label}</b></span>
                        </div>
                        {!pay && <div  className='col-6 col-md-2 text-right order-2 order-lg-3'>
                            <button className='receipe btn btn-sm lh-1 btn-primary text-white'
                                onClick={()=>{
                                    this.setState({step:1, phoneNumber:null,prefixSelected:null,confirmNumber:false});
                                }}>
                                {t('edit')}
                            </button>
                        </div>}
                        {pay && <div className='col-6 col-md-2 text-right order-2 order-lg-3    '> &nbsp;</div>}
                </div>
                 }
                {step > 1 && phoneNumber &&  <div className='row justify-content-between mt-4 flex-wrap'>
                        <div className='col-6 col-md-2 text-left order-1'>{t('Phone')}: </div>
                        <div className='col-12 col-md-8 text-center order-3 order-lg-2'><b>{prefixSelected.rlabel} {phoneNumber}</b></div>
                        {!pay && <div className='col-6 col-md-2 text-right order-2 order-lg-3'>
                            <button className='receipe btn btn-sm lh-1 btn-primary text-white'
                                onClick={()=>{
                                    this.setState({step:1, phoneNumber:null,confirmNumber:false});
                                }}>
                                {t('edit')}
                            </button>
                        </div>}
                        {pay && <div className='col-6 col-md-2 text-right order-2 order-lg-3'> &nbsp;</div>}
                </div>
                }

                {step === 2 && providersData && providersData.length &&
                    <div>
                        <div className='mt-4 mb-4'><h4>{t('recharges.select the provider')}</h4></div>
                        <CustomNavDropdown id="providers" items={providersData} itemkey="id" itemsearch={'name'} cols={['logo', 'name']}
                            onSelect= {this.handleSelectProvider}/>
                        <div className='d-flex justify-content-center'>
                            <Button type="button" className='btn-style btn-primary' onClick={this.setProviderSelected}> {t('next')}</Button>
                        </div>

                    </div>
                 }
                {step > 2 && providerSelected && <div className='row justify-content-between mt-4 flex-wrap'>
                        <div className='col-6 col-md-2 text-left order-1'>{t('recharges.Provider')}: </div>
                        <div className='col-12 col-md-8 text-center order-3 order-lg-2'>
                            <b>{providerSelected.name}</b>
                        </div>
                        {!pay && <div className='col-6 col-md-2 text-right order-2 order-lg-3'>
                            <button className='receipe btn btn-sm lh-1 btn-primary text-white'  onClick={this.reloadProvider}>{t('edit')}</button></div>}
                        {pay && <div className='col-6 col-md-2 text-right order-2 order-lg-3'> &nbsp;</div>}
                </div>}

                {step === 3 && !productsSelected &&
                    <div>
                        {productsData && productsData.length && <>
                        <div className='mt-4 mb-4'><h4>{t('home.select top-up amount')}</h4></div>
                        <CustomNavDropdown id="products" items={productsData} itemkey="id" itemsearch={'receive_value'} cols={['receive_value', 'receive_currency']}
                            onSelect= {this.handleSelectProduct}/>
                        </>}

                        {/* BONUS */}
                        {bonusData && bonusData.length && <>
                            <div className='mt-4 mb-4'><h4>{t('recharges.You can also select one of these bonuses')}</h4></div>
                            <CustomCarousel id="bonus" items={bonusData} provider={providerSelected} itemkey="id"
                            onSelect= {this.handleSelectBonus}/>
                        </>}

                        <div className='d-flex justify-content-center'>
                            <Button type="button" className='btn-style btn-secondary' onClick={this.setProductSelected}> {t('next')}</Button>
                        </div>
                    </div>
                }
                {step === 3 && productsSelected &&
                    <div className='mt-4 mb-4'>  {/* instant or not instant */}
                        <div className='row justify-content-between'>
                            <div className='col font-weight-bold text-left lh-1'> {t('recharges.Recharge amount')}: </div>
                            <div className='col h4 text-primary font-weight-bold'>  {productsSelected.receive_value} {productsSelected.receive_currency}</div>
                            {!pay && (
                                (Array.isArray(productsData) && productsData.length > 1) ||
                                (Array.isArray(bonusData) && bonusData.length > 1)
                            ) && <div className='col text-right'>
                                <button className='receipe btn btn-sm lh-1 btn-primary text-white'
                                    onClick={()=>{
                                        this.setState({step:3, productsSelected:null});
                                    }}>
                                    {t('edit')}
                                </button>
                            </div>}
                            {pay && <div className='col'> &nbsp;</div>}
                        </div>
                        {productsSelected.observation && <div className='w-100 text-center opacity-75 small'> {productsSelected.observation} </div>}

                        <div className='mt-3 font-weight-bold text-left'>{t('recharges.Amount to pay')}:</div>
                        <CustomNavDropdown id="recharge" items={rechargeTypeData} itemkey="id" itemsearch={'amount'} cols={['name', 'amount']}
                            onSelect= {this.handleSelectRechargeType}/>
                        <div className='d-flex justify-content-center'>
                            <Button type="button" className='btn-style btn-secondary' onClick={this.chooseRechargeType}> {t('next')}</Button>
                        </div>

                    </div>
                }



                {step > 3 && orderData && <>
                    <div className='d-flex justify-content-between'>
                        <div className='text-left'>
                            {t('recharges.Recharge amount')}:
                            {productsSelected.observation && <div className='w-100 text-right opacity-75 small mb-2'> {productsSelected.observation} </div>}
                        </div>
                        <div className='h4'>{orderData.receive_value} {orderData.receive_currency}</div>
                    </div>

                    {orderMode === 'instant' &&
                    <div className='d-flex justify-content-between'>
                        <div>{t('recharges.Amount to pay')} <b>{t('recharges.instant pay')}:</b></div>
                        <div className='h4'><b>{orderData.send_value} {orderData.send_currency}</b></div>
                    </div>}
                    {orderMode === 'not_instant' &&
                    <div className='d-flex justify-content-between'>
                        <div>{t('recharges.Amount to pay')} <b>{t('recharges.no instant pay')}</b></div>
                        <div className='h4'><b>{orderData.send_value} {orderData.send_currency}</b></div>
                    </div>}
                    {!beneficiaryData && <>
                        <InputGroup className='group-radio'>
                            <Form.Check type="radio" inline className='text-primary mt-3' label={t('recharges.save the beneficiary name for your next recharges')}
                                onChange={this.handleCheckboxChange}
                            />
                        </InputGroup>
                        {this.state.showBeneficiaryName && (
                        <div>
                            <Form.Control name="beneficiary_name" ref={this.beneficiaryName} focus
                                placeholder={t('account.name')}
                            />
                        </div>
                        )}
                   </> }

                    {step < 5 && <div className='text-center mt-5 d-lg-flex justify-content-between'>
                        <Button  as="input" type="button" className='btn-style  m-1 col-lg-6 col-12' value={t('cancel')} onClick={this.resetOrder} />
                        <Button as="input" type="button" className='btn-style btn-secondary m-1 col-lg-6 col-12' value={t('recharges.buttonPay')} onClick={this.sendOrder} />
                    </div>}


                    {step === 5 && <> {/*checkout*/}
                        <ProgressBar animated now={this.state.paymentProgress} />
                        <div className='text-center text-dark small'>{t('recharges.You will be immediately redirected to our secure payment gateway')}</div>
                        {responseCheckout && <Form ref={this.payformRef} id="redsysForm" action="https://sis.redsys.es/sis/realizarPago" method="POST">
                            <Form.Control type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1" />
                            {responseCheckout.Ds_Signature && <Form.Control type="hidden" name="Ds_Signature" value={responseCheckout.Ds_Signature} />}
                            {responseCheckout.Ds_MerchantParameters && <Form.Control type="hidden" name="Ds_MerchantParameters" value={responseCheckout.Ds_MerchantParameters} />}
                            <Button type='submit' className='d-none'/>
                        </Form> }
                    </>}
                </>}



            </div> }


        </>}</Translation>)
    }
}

// Componente funcional para pasar navigate como prop
function RechargeWithNavigate(props) {
    const navigate = useNavigate();
    return <Recharge {...props} navigate={navigate} />;
}

export default RechargeWithNavigate;
