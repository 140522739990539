import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import helper from "../../helper/fn.js";

const RechargerHandler = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const pathParts = location.pathname.split('/');
        const encodedData = pathParts[pathParts.length - 1];

        if (!isBase64(encodedData)) {
            navigate('*');
            return;
        }

        try {
            const base64DecodedBytes = Uint8Array.from(atob(encodedData), c => c.charCodeAt(0));
            const base64Decoded = new TextDecoder("utf-8").decode(base64DecodedBytes);
            const data = JSON.parse(base64Decoded);

            if (!isValidFormat(data)) {
                throw new Error("Invalid JSON format");

            }
            helper.clearClientStorage();
            sendRecharge(data);
        } catch (error) {
            navigate('*');
        }
    }, [location.pathname, navigate]);

    const isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };

    const isValidFormat = (json) => {
        const hasExpectedKeys = json.phone && Array.isArray(json.telephone_prefixes);
        if (!hasExpectedKeys) return false;

        return json.telephone_prefixes.every(prefix => {
            return typeof prefix.id === 'number' &&
                typeof prefix.country_iso === 'string' &&
                typeof prefix.country_name === 'string' &&
                typeof prefix.img_flag === 'string' &&
                typeof prefix.prefix === 'string';
        });
    };

    const sendRecharge = (data) => {
        let dataToSend = { ...data };
        dataToSend.phone = data.phone;
        dataToSend.requiredConfirm = 'no';
        helper.sendRecharge(dataToSend);
    };

    return (
        <></>
    );
};

export default RechargerHandler;