import React from 'react';
import env from "../helper/env.js";
import {useTranslation} from "react-i18next";
import { Helmet } from 'react-helmet';
import RatingStar from "../components/Utils/ratingStar";
import RechargeRequestComponent from "../components/Services/RechargeRequest.js";

function RechargeRequest() {
    const {t} = useTranslation(['translation']);
    const starImagePath = `${env.img_basepath}star.svg`;


    return (
        <>
            <Helmet><link rel="canonical" href={env.base_url + 'solicitar'} /> </Helmet>
            <section className="main-section pt-5 pb-5 mb-5">
                <div className="container content-space-2">
                    <div className="row justify-content-center align-items-lg-center">
                        <div className="col-md-8 col-lg-6 mb-7 mb-lg-0 order-2 order-lg-1">
                            <div className="pe-lg-3 mb-7">
                                <h2 className="display-3 text-white mb-3 mb-md-5">{t('rechargeRequest.title')}</h2>
                                <p className="lead text-white">{t('rechargeRequest.description')}</p>
                            </div>

                            <div className="row mt-5">
                                <div className="col-sm-6 mb-3 mb-sm-0">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                        <span className="svg-icon text-white">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path  d="M22.1671 18.1421C22.4827 18.4577 23.0222 18.2331 23.0206 17.7868L23.0039 13.1053V5.52632C23.0039 4.13107 21.8729 3 20.4776 3H8.68815C7.2929 3 6.16183 4.13107 6.16183 5.52632V9H13C14.6568 9 16 10.3431 16 12V15.6316H19.6565L22.1671 18.1421Z" fill="#FFFFFF"></path>
                                            <path opacity="0.3"  d="M1.98508 18V13C1.98508 11.8954 2.88051 11 3.98508 11H11.9851C13.0896 11 13.9851 11.8954 13.9851 13V18C13.9851 19.1046 13.0896 20 11.9851 20H4.10081L2.85695 21.1905C2.53895 21.4949 2.01123 21.2695 2.01123 20.8293V18.3243C1.99402 18.2187 1.98508 18.1104 1.98508 18ZM5.99999 14.5C5.99999 14.2239 6.22385 14 6.49999 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H6.49999C6.22385 15 5.99999 14.7761 5.99999 14.5ZM9.49999 16C9.22385 16 8.99999 16.2239 8.99999 16.5C8.99999 16.7761 9.22385 17 9.49999 17H11.5C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16H9.49999Z" fill="#FFFFFF"></path>
                                        </svg>

                                        </span>
                                        </div>

                                        <div className="flex-grow-1 ms-4">
                                            <h6 className="text-white mb-0">2 {t('rechargeRequest.hours')}</h6>
                                            <p className="text-white-70">{t('rechargeRequest.Response time')}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                        <span className="svg-icon text-white">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M4.875 20.75C4.63542 20.75 4.39583 20.6542 4.20417 20.4625L2.2875 18.5458C1.90417 18.1625 1.90417 17.5875 2.2875 17.2042C2.67083 16.8208 3.29375 16.8208 3.62917 17.2042L4.875 18.45L8.0375 15.2875C8.42083 14.9042 8.99583 14.9042 9.37917 15.2875C9.7625 15.6708 9.7625 16.2458 9.37917 16.6292L5.54583 20.4625C5.35417 20.6542 5.11458 20.75 4.875 20.75Z" fill="#FFFFFF"></path>
                                            <path d="M5 3C3.34315 3 2 4.34315 2 6V11.865C3.04486 11.3127 4.23591 11 5.5 11C9.47412 11 12.7263 14.091 12.9836 18H18.0249L20.3178 20.2929C20.9478 20.9229 22.0249 20.4767 22.0249 19.5858V15L22 15.0098L22 15V6C22 4.34315 20.6569 3 19 3H5ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44771 9 6 9H15C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7H6Z" fill="#FFFFFF"></path>
                                        </svg>

                                        </span>
                                        </div>

                                        <div className="flex-grow-1 ms-4">
                                            <h6 className="text-white mb-0">92%</h6>
                                            <p className="text-white-70">{t('rechargeRequest.Satisfaction rate')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8 col-lg-6 order-1 order-lg-2 mb-5">
                            <div className="ps-lg-5">
                                <div className="card card-lg">
                                    <div className="card-body p-5">
                                        <div className="row align-items-sm-center mb-5">
                                            <div className="col-md-4 col-12 order-sm-2 mb-3 mb-sm-0">
                                                <img className="avatar avatar-xxl avatar-4x3" src={env.img_basepath +"oc-chatting.svg"} alt={t('Recharge')}/>
                                            </div>

                                            <div className="col-md-8 col-12">
                                                <h3 className="card-title">{t('rechargeRequest.title2')}</h3>
                                                <p className="card-text">{t('rechargeRequest.description2')}</p>
                                            </div>

                                        </div>
                                        <RechargeRequestComponent/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-top border-white-10 my-10"></div>
                    {/* Rating */ }
                    <div className="row mb-5">
                        <div className="col-sm mb-5 mb-sm-0">
                            <div className="d-flex gap-1 mb-2">
                                {[...Array(5)].map((_, i) => (
                                    <RatingStar key={i} src={starImagePath} alt="Review rating" width="16" />
                                ))}
                            </div>
                            <blockquote className="blockquote blockquote-sm text-white mb-4">{t('rechargeRequest.testi1')}</blockquote>
                        </div>

                        <div className="col-sm mb-5 mb-sm-0">
                            <div className="d-flex gap-1 mb-2">
                                {[...Array(4)].map((_, i) => (
                                    <RatingStar key={i} src={starImagePath} alt="Review rating" width="16" />
                                ))}
                                <img src={env.img_basepath +"star-half.svg"} alt="Review rating" width="16"/>
                            </div>

                            <blockquote className="blockquote blockquote-sm text-white mb-4">{t('rechargeRequest.testi2')}</blockquote>

                        </div>

                        <div className="col-sm mb-5">
                            <div className="d-flex gap-1 mb-2">
                                {[...Array(4)].map((_, i) => (
                                    <RatingStar key={i} src={starImagePath} alt="Review rating" width="16" />
                                ))}
                                <img src={env.img_basepath +"star-half.svg"} alt="Review rating" width="16"/>
                            </div>

                            <blockquote className="blockquote blockquote-sm text-white mb-4">{t('rechargeRequest.testi3')}</blockquote>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RechargeRequest