import env from "./env.js";

const jsHelper = {
    scrollToTop: () =>{
        window.scrollTo(0,0);
    },
    clearClientStorage: () => {
        localStorage.removeItem('from');
        localStorage.removeItem('prefixObj');
        localStorage.removeItem('phoneNumber');
        localStorage.removeItem('providerObj');
        localStorage.removeItem('beneficiaryObj');
    },
    getClientStorageData: () =>{
        const prefixObjString = localStorage.getItem('prefixObj');
        const providerObjString = localStorage.getItem('providerObj');
        return {
            phoneNumber:localStorage.getItem('phoneNumber') || '',
            prefixObj: (prefixObjString) ? JSON.parse(prefixObjString):null,
            providerObj:(providerObjString) ? JSON.parse(providerObjString):null
        }
    },
    patterPhoneNumberToSend: (prefix, phoneNumber) => {
        // Asegurarse de que prefix y phoneNumber sean cadenas
        prefix = String(prefix);
        phoneNumber = String(phoneNumber);

        const plus = (prefix && !String(prefix).startsWith('+')) ? '+' : '';
        prefix = plus + prefix;
        // Caso 1: Remover "00" o "+" inicial y ajustar el número
        if (phoneNumber.startsWith('00')) {
            phoneNumber = phoneNumber.slice(2); // Remover "00" inicial
        } else if (phoneNumber.startsWith('+')) {
            phoneNumber = phoneNumber.slice(1); // Remover "+" inicial
        }
        // Caso 2: Verificar si el número ya incluye el prefijo completo sin "+"
        if (phoneNumber.startsWith(prefix.replace('+', ''))) {
            phoneNumber = phoneNumber.slice(prefix.length - 1); // Remover prefijo duplicado
        }
        // Caso 3: Eliminar ceros iniciales en caso de que no tenga prefijo
        phoneNumber = phoneNumber.replace(/^(0+)/, '');
        // Formatear y agregar prefijo si no está presente
        const formattedPhoneNumber = prefix + phoneNumber;
        return formattedPhoneNumber.replace(/\s+/g, ''); // Retornar sin espacios
    },

    getPhoneNumberWithOutPrefix: (prefix, phoneNumber) => {
        if (!prefix) return phoneNumber;
        if (prefix.value) prefix = prefix.value;
        // Asegurarse de que prefix y phoneNumber sean cadenas
        prefix = String(prefix);
        phoneNumber = String(phoneNumber).replace(/ /g, ''); // Eliminar espacios del número

        // Normalizar el prefijo para que tenga "+"
        const normalizedPrefix = prefix.startsWith('+') ? prefix : `+${prefix}`;

        // Eliminar el prefijo del número si está presente, con o sin "+"
        let result = phoneNumber;
        if (result.startsWith(normalizedPrefix)) {
            result = result.slice(normalizedPrefix.length);
        } else if (result.startsWith(prefix)) {
            result = result.slice(prefix.length);
        }

        return result;
    },
    setPhoneNumberToStorage:(prefixSelected, phoneNumber) =>{
        const prefix = prefixSelected.value;
        let _pn = jsHelper.getPhoneNumberWithOutPrefix(prefix, phoneNumber);
        localStorage.setItem('phoneNumber', _pn);
        localStorage.setItem('prefixObj', JSON.stringify(prefixSelected));
    },
    sendRecharge: (data, navigate) =>{
        if (data && data.phone_number) data.phone = data.phone_number;
        if (!data || !data.phone) return false;

        let phoneNumber;
        /*localstorage *recharge */
        if (data.telephone_prefixes){
            const r = data.telephone_prefixes[0];
            const prefixObj = {
                img: env.img_basepath + r.img_flag,
                label:r.country_name,
                rlabel:`+${r.prefix}`,
                value:`+${r.prefix}`,
                max_length:r.max_length,
                min_length:r.min_length,
                country_iso: r.country_iso
            };
            localStorage.setItem('prefixObj',JSON.stringify(prefixObj));
            phoneNumber = data.phone.replaceAll(`+${r.prefix}`, '');
        }else{
            phoneNumber = data.phone;
        }

        localStorage.setItem('phoneNumber', phoneNumber);
        if (data.provider && data.provider_name){
            localStorage.setItem('providerObj', JSON.stringify({provider_code:data.provider, name:data.provider_name}));
        }else if (data.requiredConfirm === 'no'){
            localStorage.setItem('requiredConfirm','no');
        }
        if (data.beneficiary && data.beneficiary.name) localStorage.setItem('beneficiaryObj',JSON.stringify({name:data.beneficiary.name}));
        // Utiliza la función navigate proporcionada
        if (navigate) {
            navigate('/recharge');
        } else {
            window.location.href = '/recharge';
        }
    }


}
export default jsHelper;