import React, {useEffect} from "react";  // Importa useCallback
import { useTranslation } from 'react-i18next';
import {  NavLink } from 'react-router-dom';
import env from "../../helper/env.js";

const CustomHeader = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.getElementById('root').style.display = 'block';
        document.body.classList.add('landing-page');
    });


    return (
        <header id="site-header" className="fixed-top">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-dark stroke">
                    <h1>
                        <NavLink
                            to="/"
                            className="navbar-brand"
                            onClick={() => {
                                document.body.classList.remove('landing-page');
                            }}
                        >
                            <span className="logohash"></span>
                            {env.app_name} <span className="logo">{t("header slogan")}</span>
                        </NavLink>
                    </h1>

                </nav>
            </div>
        </header>
    );
};

export default CustomHeader;
