import React from 'react';
import RechargerHandler from "../components/Services/RechargerHandler";

function RechargeHandler() {
    return (
        <>
            <RechargerHandler />
        </>
    )
}
export default RechargeHandler