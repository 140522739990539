import React from 'react';
import { Translation } from 'react-i18next';
import callServerApi from "../Utils/apiManager";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-phone-number-input/style.css';
import ResponseAlert from "../Common/ResponseAlert";

class RechargeRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            responseError: '',
            responseSuccess: '',
            phoneNumber: '',
            prefix: '',
            countryCode: '',
            name: '',
            email:'',
            clientFound:false,
            firstRequest: false,requestCreated: false,
        };
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
    }

    handlePhoneChange = (value) => {
        let countryPrefix = '',
            countryCode = '' ;
        if (value && value.length > 4) {
            try {
                const phoneNumber = parsePhoneNumberFromString(value);
                if (phoneNumber) {
                    countryPrefix = `+${phoneNumber.countryCallingCode}`;
                    countryCode = phoneNumber.country;
                }
            } catch (error) {
                console.error('Error obteniendo el prefijo del país:', error);
            }
        }

        this.setState({ phoneNumber: value, prefix: countryPrefix, countryCode: countryCode });
    };

    handleNameChange = (e) => {
        this.setState({ name: e.target.value });
    };
    handleEmailChange = (e) => {
        this.setState({ email: e.target.value });
    };
    handleSubmit = (e) => {
        e.preventDefault();
        const { phoneNumber,firstRequest } = this.state;

        if (!phoneNumber) {
            this.setState({ responseError: 'All fields are required.' });
            return;
        }
        this.setState({loading:true});
        if (firstRequest){
            this.createRequest();
        }else{
            callServerApi('ws/recharge-request', 'POST', { phoneNumber})
                .then((result) => {
                    if (result.data && result.data.email) {
                        this.setState({clientFound:true, email:result.data.email, firstRequest:true});
                        this.createRequest(result.data);
                    }else{
                        this.setState({loading:false, firstRequest:true});
                    }
                })
                .catch((error) => {
                    this.setState({
                        responseError: error.data?.error || 'An error occurred.',
                        loading: false,
                    });
                    setTimeout(() => this.setState({ responseError: '' }), 5000);
                });
        }
    };

    createRequest = (data) =>{
        const { phoneNumber, prefix, countryCode, name, email } = this.state;

        if (!email && !data && !data.email) {
            this.setState({ loading:false, responseError: 'All fields are required.' });
            return;
        }
        this.setState({ requestCreated: true});

        const oToSend = {phoneNumber:phoneNumber, prefix:prefix, countryIsos:countryCode, name:name, email:email || data.email };
        if (data && data.provider_id) oToSend.provider_id = data.provider_id;

        callServerApi('ws/create-recharge-request', 'POST', oToSend)
            .then((result) => {
                if (result.status === 'ok') {
                    this.setState({loading:false, responseSuccess: result.message});
                    setTimeout(() =>{ this.setState({responseSuccess:''})}, 5000);
                }
                this.reload();
            })
            .catch((error) => {
                this.setState({
                    responseError: error.data?.error || 'An error occurred.',
                    loading: false,
                });
                setTimeout(() => this.setState({ responseError: '' }), 5000);
                this.reload();
            });
    }
    reload =() =>{
        this.setState({
            phoneNumber:'',
            name: '',
            email:'',
            countryIsos:'',
            prefix:'',
            loading: false,
            firstRequest: false,
        });
    }
    render() {
        const { responseSuccess, responseError, phoneNumber, name, email, clientFound, firstRequest, requestCreated, loading } = this.state;

        return (
            <Translation ns={['translation']}>
                {(t) => (
                    <Form id="recharge-request" onSubmit={this.handleSubmit}>

                        {responseSuccess && (
                            <ResponseAlert response={responseSuccess} variant="success" />
                        )}
                        {responseError && (
                            <ResponseAlert response={responseError} variant="danger" />
                        )}

                        <div className="row">
                            <div className="col-12">
                                <InputGroup>
                                    <Form.Control autoFocus
                                        className="form-control PhoneInput"
                                        name="name"
                                        placeholder={t('contact.name')}
                                        required
                                        value={name}
                                        onChange={this.handleNameChange}
                                        disabled={loading}
                                    />
                                </InputGroup>
                            </div>
                            {firstRequest &&  !clientFound && <div className="col-12 mb-3">
                                <p className='text-secondary' style={{'fontSize': '1em'}}>
                                    {t('rechargeRequest.clientNofound1')}
                                    <b> {phoneNumber} </b>
                                    <br/>{t('rechargeRequest.clientNofound2')}
                                </p>
                            </div>}
                            <div className="col-12 col-lg-7 mb-3">
                                {!firstRequest &&
                                    <PhoneInput
                                        placeholder={t('form.enter phone number')}
                                        value={phoneNumber}
                                        onChange={this.handlePhoneChange}
                                        className="form-control"
                                        defaultCountry="ES"
                                        international
                                        disabled={loading}
                                    />
                                }

                                {firstRequest && !requestCreated &&
                                <InputGroup>
                                    <Form.Control
                                        className="form-control PhoneInput"
                                        name="email"
                                        placeholder={t('contact.email')}
                                        required
                                        value={email}
                                        onChange={this.handleEmailChange}
                                        disabled={loading}
                                    />
                                </InputGroup>
                                }
                            </div>

                            <div className="col-12 col-lg-5 mb-3 text-center">
                                <button type="submit" className="btn btn-primary btn-style" disabled={loading}>
                                  {loading ? (
                                    <>
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                        &nbsp;{t('sending')}...
                                    </>
                                 ) : (
                                    t('next')
                                )}
                                </button>
                            </div>


                        </div>
                    </Form>
                )}
            </Translation>
        );
    }
}

export default RechargeRequest;
