import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Header from "./views/elements/Header";
import CustomHeader from "./views/elements/CustomHeader"; // Nuevo Header
import Footer from "./views/elements/Footer";

import Home from "./views/home";
import Recharge from "./views/recharge";
import About from "./views/about";
import LegalNotice from "./views/legal-notice";
import PrivacyPolicy from "./views/privacy-policy";
import CookiePolicy from "./views/cookie-policy";
import Contact from "./views/contact";
import Faq from "./views/frequently-asked-questions";
import TermsConditions from "./views/terms-conditions";

import Signup from "./views/auth/register";
import Signin from "./views/auth/login";
import PaymentOK from "./views/payment-confirmation-ok";
import PaymentKO from "./views/payment-confirmation-ko";

import NotFound from "./views/notFound";
import Unsubscribe from "./views/unsubscribe";
import RechargeRequest from "./views/recharge-request";
import RechargeHandler from "./views/recharge-handler";

import { AuthProvider } from "./components/Auth/AuthContext";
import Logout from './components/Auth/Logout';
import ProtectedRoute from './views/auth/protectedRoute';
import Account from './views/client/account';
import History from './views/client/history';
import Beneficiaries from './views/client/beneficiaries';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/custom.css';


function MainApp() {
    const location = useLocation();

    // Determina si la ruta actual es "/solicita" para usar un encabezado personalizado
    const showCustomHeader = location.pathname === '/solicita';

    return (
        <AuthProvider>
            {showCustomHeader ? <CustomHeader /> : <Header />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route exact="true" path="/about" element={<About />} />
                <Route exact="true" path='/legal-notice' element={<LegalNotice/>} />
                <Route exact="true" path='/privacy-policy' element={<PrivacyPolicy/>} />
                <Route exact="true" path='/terms-conditions' element={<TermsConditions/>} />
                <Route exact="true" path='/cookie-policy' element={<CookiePolicy/>} />
                <Route exact="true" path='/faqs' element={<Faq/>} />
                <Route exact="true" path="/contact" element={<Contact />} />

                <Route exact="true" path="/signup" element={<Signup />} />
                <Route exact="true" path="/signin" element={<Signin />} />
                <Route path="/unsubscribe/:result" element={<Unsubscribe />} />

                {/* Rutas recargas */}
                <Route exact="true" path="/recharge" element={<Recharge />} />
                <Route path="/solicita" element={<RechargeRequest />} />
                <Route exact="true" path="/s/:encodedData" element={<RechargeHandler />} />

                {/* Rutas pagos */}
                <Route path="/payment-confirmation/ok" element={<PaymentOK />} />
                <Route path="/payment-confirmation/ko" element={<PaymentKO />} />

                {/* Rutas protegidas */}
                <Route element={<ProtectedRoute />}>
                    <Route path="account" element={<Account />} />
                    <Route path="history" element={<History />} />
                    <Route path="beneficiaries" element={<Beneficiaries />} />
                    <Route path="logout" element={<Logout />} />
                </Route>

                {/* Ruta para la página 404 */}
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
        </AuthProvider>
    );
}

function App() {
    return (
        <BrowserRouter>
            <MainApp />
        </BrowserRouter>
    );
}

export default App;
